<script setup>
import { ref, onBeforeMount } from "vue";
import AutoScroll from "@/components/home/AutoScroll.vue";

const listCashOut = ref([]);
const listCashIn = ref([]);

onBeforeMount(() => {
  // var token_user = window.localStorage.getItem('token')
  // this.axios.get(this.api_listCo, {
  //     headers: {
  //         Authorization: 'Bearer ' + token_user
  //     }
  // }).then((response) => {
  //     this.listCashouthome = response.data.data
  // })
  let today = new Date();
  let date =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  listCashOut.value = [
    { fullname: "aa***", amount: "1000000", created_at: date },
    { fullname: "db***", amount: "2000000", created_at: date },
    { fullname: "hg***", amount: "3500000", created_at: date },
    { fullname: "tg***", amount: "800000", created_at: date },
    { fullname: "wj***", amount: "1000000", created_at: date },
    { fullname: "qb***", amount: "3000000", created_at: date },
    { fullname: "mk***", amount: "500000", created_at: date },
    { fullname: "sx***", amount: "1000000", created_at: date },
    { fullname: "zj***", amount: "4000000", created_at: date },
    { fullname: "zj***", amount: "2000000", created_at: date },
  ];

  listCashIn.value = [
    { fullname: "nm***", amount: "500000", created_at: date },
    { fullname: "vc***", amount: "300000", created_at: date },
    { fullname: "bf***", amount: "1000000", created_at: date },
    { fullname: "pl***", amount: "800000", created_at: date },
    { fullname: "qy***", amount: "500000", created_at: date },
    { fullname: "io***", amount: "3000000", created_at: date },
    { fullname: "ka***", amount: "5000000", created_at: date },
    { fullname: "ok***", amount: "1000000", created_at: date },
    { fullname: "xc***", amount: "800000", created_at: date },
    { fullname: "dh***", amount: "1500000", created_at: date },
  ];
});
</script>

<template>
  <section
    class="lqd-section testi-pricing jackpot_wrap pt-60 pb-100"
    style="background-image: linear-gradient(180deg, #dbfef1 0%, #fff 25%)"
  >
    <div class="container-fluid">
      <div class="row justify-center">
        <div
          class="col col-12 col-md-8 mb-60 p-0 text-center"
          data-custom-animations="true"
          data-ca-options='{"animationTarget": ".animation-element, figure, .lqd-split-lines .lqd-lines .split-inner", "duration" : 1000, "delay" : 100, "ease": "expo.out", "initValues": {"y": "60px", "opacity" : 0}, "animations": {"y": "0px", "opacity" : 1}}'
        >
          <div class="module-shape-1 absolute max-w-full z-1">
            <div class="lqd-imggrp-single block relative">
              <div
                class="lqd-imggrp-img-container inline-flex relative items-center justify-center"
              >
                <figure class="w-full relative">
                  <img
                    width="540"
                    height="178"
                    src="/assets_nx/images/common/left.svg"
                    alt="shape"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div class="module-shape-2 absolute max-w-full z-1 sm:hidden">
            <div class="lqd-imggrp-single block relative">
              <div
                class="lqd-imggrp-img-container inline-flex relative items-center justify-center"
              >
                <figure class="w-full relative">
                  <img
                    width="540"
                    height="189"
                    src="/assets_nx/images/common/right.svg"
                    alt="shape"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-center">
            <div class="ld-fancy-heading relative mask-text">
              <h2
                class="ld-fh-element relative lqd-highlight-classic lqd-highlight-grow-left lqd-split-lines h2 mb-0/25em"
                data-split-text="true"
                data-split-options='{"type": "lines"}'
              >
                <span class="lqd-adv-txt-item">今日の出入り</span>
                <span class="relative z-3 lqd-adv-txt-item">
                  <span
                    class="relative inline-flex rounded-full vertical-middle -mr-30"
                  >
                    <img
                      class="lqd-adv-txt-fig w-55 rounded-full"
                      decoding="async"
                      width="58"
                      height="58"
                      src="/assets_nx/images/icons/casino-chip-clover.gif"
                      alt="testimonials avatar"
                    />
                  </span>
                </span>
                <span class="relative z-2 lqd-adv-txt-item">
                  <span
                    class="relative inline-flex rounded-full vertical-middle -mr-35"
                  >
                    <img
                      class="lqd-adv-txt-fig w-55 rounded-full"
                      decoding="async"
                      width="58"
                      height="58"
                      src="/assets_nx/images/icons/casino-chip-heart.gif"
                      alt="testimonials avatar"
                    />
                  </span>
                </span>
                <span class="relative z-1 lqd-adv-txt-item">
                  <span
                    class="relative inline-flex rounded-full vertical-middle"
                  >
                    <img
                      class="lqd-adv-txt-fig w-55 rounded-full"
                      decoding="async"
                      width="58"
                      height="58"
                      src="/assets_nx/images/icons/casino-chip-spade.gif"
                      alt="testimonials avatar"
                    />
                  </span>
                </span>
              </h2>
            </div>
            <div
              data-parallax="true"
              data-parallax-options='{"start": "top bottom", "end": "bottom+=0px top"}'
              data-parallax-from='{"y": "100px", "scaleX" : 1, "scaleY" : 1}'
              data-parallax-to='{"y": "-100px", "scaleX" : 1.1, "scaleY" : 1.1}'
            >
              <div
                class="pt-40 pb-30 px-40 flex flex-wrap transition-all box-shadow-bottom w-full bg-no-repeat bg-center bg-white bg-contain relative rounded-10 rounded-bottom-0 module-section"
              >
                <!--                <div class="jackpot_num animation-element w-full">-->
                <!--                  <div class=" ml-10 ld-fancy-heading relative animation-element">-->
                <!--                    <h2-->
                <!--                      class="ld-fh-element inline-block ld-gradient-heading relative text-65 bg-transparent module-gradient lqd-unit-animation-done"-->
                <!--                      style="background-image: linear-gradient(50deg, rgb(141, 101, 233) 0%, rgb(107, 205, 148) 100%);">-->
                <!--                      Jackpot</h2>-->
                <!--                  </div>-->
                <!--                  <div class="text-primary lqd-counter relative left lqd-counter-default">-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="flex gap-4">
                  <AutoScroll :items="listCashIn" />
                  <AutoScroll :items="listCashOut" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
