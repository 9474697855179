<template>
    <div class="minigame-wrapper">
        <div class="iframe-wrapper">
            <iframe src="https://powerballplus.org/powerballplus5.php" scrolling="no" class="iiiiii" autoplay="1" allow="autoplay"></iframe>
        </div>
        <div class="minigame-menu">
            <div class="header">
                <div class="h-item">
                    <div class="title">회차</div>
                    <div class="value">360</div>
                </div>
                <div class="h-item">
                    <div class="title">마감</div>
                    <div class="value">00:18</div>
                </div>
            </div>
            <div class="betting-type">
                <button class="active">일반배팅</button>
                <button>조합배팅</button>
            </div>
            <div class="betting-options">
                <div class="option-grp my-col-5">
                    <div class="option-name">파워볼</div>
                    <div class="my-col my-col-4">
                        <div class="option disabled">
                            <div class="sides">1.95</div>
                            <div class="ball blue">홀</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">1.95</div>
                            <div class="ball red">짝</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">1.95</div>
                            <div class="ball blue-outline">언더</div>
                            <div class="sides bottom">4.5</div>
                        </div>
                        <div class="option">
                            <div class="sides">1.95</div>
                            <div class="ball red-outline">오버</div>
                            <div class="sides bottom">4.5</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="betting-options">
                <div class="option-grp my-col-5">
                    <div class="option-name">일반볼</div>
                    <div class="my-col my-col-4">
                        <div class="option">
                            <div class="sides">1.95</div>
                            <div class="ball blue">홀</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">1.95</div>
                            <div class="ball red">짝</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">1.95</div>
                            <div class="ball blue-outline">언더</div>
                            <div class="sides bottom">72.5</div>
                        </div>
                        <div class="option">
                            <div class="sides">1.95</div>
                            <div class="ball red-outline">오버</div>
                            <div class="sides bottom">72.5</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="betting-options">
                <div class="option-grp my-col-4">
                    <div class="option-name">일반볼구간</div>
                    <div class="my-col my-col-3">
                        <div class="option">
                            <div class="sides">2.8</div>
                            <div class="ball red">대</div>
                            <div class="sides bottom">81-130</div>
                        </div>
                        <div class="option">
                            <div class="sides">2.50</div>
                            <div class="ball blue">중</div>
                            <div class="sides bottom">65-80</div>
                        </div>
                        <div class="option">
                            <div class="sides">2.8</div>
                            <div class="ball green">소</div>
                            <div class="sides bottom">16-64</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="betting-options">
                <div class="option-grp">
                    <div class="option-name">파워볼숫자</div>
                    <div class="my-col my-col-5">
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">1</div>  
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">2</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">3</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">4</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">5</div>
                            <div class="sides bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="betting-options">
                <div class="option-grp">
                    <div class="option-name">파워볼숫자</div>
                    <div class="my-col my-col-5">
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">6</div>  
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">7</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">8</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">9</div>
                            <div class="sides bottom"></div>
                        </div>
                        <div class="option">
                            <div class="sides">9.00</div>
                            <div class="ball black">0</div>
                            <div class="sides bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bet-history">
        <div class="header">최근배팅내역</div>
        <div class="body">
            <span>최근 배팅내역이 없습니다.</span>
        </div>
    </div>
</template>
<script>

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";



export default {
    name: "mini_games",
    data(){
        return{
            listconvert: [],
            dataListConvert: [],
            page: 1,
            perPage: 15,
            totalCount: 0,
            showModal_pow: false,
            betAmount:0,
            hitAmount:0,
            multiplier:0,
            pickName:'',
            pick:'',
            picknum:0,
            typepick:0,
            total_mn:0,
            total_cash:0,
            time:null,
            timer:0,
            round:0,
            todayround : 0,
            remindtimer :0,
            msgtxt:'',
            rate_nor_oddeven:0,
            rate_nor_underover:0,
            rate_nor_cross:0,
            rate_pow_oddeven:0,
            rate_pow_underover:0,
            rate_pow_cross1:0,
            rate_pow_cross2:0,
            idx:0
        }
    },
    created () {
        const token = window.localStorage.getItem("token");
        if (token !== null) 
        {         
            //setTimeout(this.getListInfo, 10000);
            //setTimeout(this.getHistoryGame, 10000);
            this.intervalId = setInterval(() => {
                this.getHistoryGame();
                this.getListInfo();
        }, 5000);

        }      
    },
    mounted(){
        const token = window.localStorage.getItem("token");
        if (token !== null) {
            
            this.getListInfo()     
            this.getListGame()    
            this.loadMoney ()   
        }       
        this.updateClock()
        //this.getHistoryGame() 
       
        
    },
    beforeUnmount() {
        clearInterval(this.intervalId);
    },
    methods:{
        getListGame () {
           
            var token_user = window.localStorage.getItem('token')
            this.axios.post(this.api_infogame,    
                {       
                    gamecode: 1
                },
                { headers: {
                    Authorization: 'Bearer ' + token_user
                }},
            ).then(res => {

                const masTime = new Date(res.data.data[0].end_bet_time);
                const todayTime = new Date();
                const diff = masTime - todayTime;


                const diffMin = Math.floor((diff / (1000*60)) % 60);
                const diffSec = Math.floor(diff / 1000 % 60);

                this.idx = res.data.data[0].id;
                this.round = res.data.data[0].round_id;
                this.todayround = res.data.data[0].todayround;
                this.remindtimer = diffSec + (diffMin*60)

              
                this.rate_nor_oddeven = res.data.data[0].nor_oddeven_rate
                this.rate_nor_underover = res.data.data[0].nor_underover_rate
                this.rate_nor_cross = res.data.data[0].nor_cross_rate
                this.rate_pow_oddeven = res.data.data[0].pow_oddeven_rate
                this.rate_pow_underover = res.data.data[0].pow_underover_rate
                this.rate_pow_cross1 = res.data.data[0].pow_cross_rate
                this.rate_pow_cross2 = res.data.data[0].pow_cross_rate2
                

                this.updateTimer(this.remindtimer)
                this.clearBets();


            }).catch(e => {
                console.log(e)
            })
        },
        getHistoryGame () {
           
           var token_user = window.localStorage.getItem('token')
           this.axios.post(this.api_historygame,    
               {       
                   gamecode: 1
               },
               { headers: {
                   Authorization: 'Bearer ' + token_user
               }},
           ).then(res => {

                //console.log(res.data.data);
                this.listconvert = res.data.data;
                this.totalCount = this.listconvert.length
                this.paginatedData()


           }).catch(e => {
               console.log(e)
           })
       },
        paginatedData () {
            this.dataListConvert = this.listconvert.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        Betting(){

            if(this.pick == 0)
            {
                this.$swal({
                    position: 'top',
                    icon: 'fail',
                    title: "픽을 선택하세요!",
                    showConfirmButton: false,
                    timer: 4000
                })
            }
            else{
                if(this.betAmount <= 0)
                {
                    this.$swal({
                        position: 'top',
                        icon: 'fail',
                        title: "배팅금액을 입력하세요!",
                        showConfirmButton: false,
                        timer: 4000
                    })
                }
                else{
                    var token_user = window.localStorage.getItem('token')
                    this.axios.post(this.api_betting,    
                        {       
                            seqidx: this.idx,
                            gametype:1,
                            iround: this.round,
                            amount: this.betAmount,
                            pick:this.picknum,
                            picktype:this.typepick
                        },
                        { headers: {
                            Authorization: 'Bearer ' + token_user
                        }},
                    ).then(res => {

                        if(res.data.data.errorcode == 0)
                        {
                            this.msgtxt = res.data.data.msg;
                          
                            this.$swal({
                                position: 'top',
                                icon: 'success',
                                title: this.msgtxt,
                                showConfirmButton: false,
                                timer: 4000
                            })
                            
                            this.clearBets();
                            this.getListInfo();
                            this.getHistoryGame();
                        }
                        else if(res.data.data.errorcode == 9)
                        {
                            this.msgtxt = res.data.data.msg;
                            //this.showModal_pow = true;

                            this.$swal({
                                position: 'top',
                                icon: 'fail',
                                title: this.msgtxt,
                                showConfirmButton: false,
                                timer: 4000
                            })
                            this.clearBets();
                        }
                        else{
                            this.msgtxt = res.data.data.msg;
                            this.showModal_pow = true;
                        }

                        

                    }).catch(e => {
                        console.log(e)
                    })
                }
                
            }
            
            
        },
       
        setBet(data){
            if(this.total_mn > (this.betAmount+data))
            {
                this.betAmount += data  
                this.updateHit()
            }
            else{
                this.$swal({
                    position: 'top',
                    icon: 'fail',
                    title: "保有金額が不足しています。",
                    showConfirmButton: false,
                    timer: 4000
                })
            }
            
        },
        clearBets(){
            this.hitAmount = 0
            this.betAmount = 0
            this.multiplier = 0
            this.pickName = ''
            this.picknum = 0
            this.typepick = 0
            this.setPick('')
        },
        setMax(){
            if(this.multiplier != 0){
                this.betAmount =  this.total_mn
                this.updateHit()
            }
        },
        setPick(data){
            this.pick = data

          
            switch(data){
                case 'odd_n':
                    this.multiplier = this.rate_nor_oddeven
                    this.pickName = '일반볼 홀'
                    this.picknum = 1
                    this.typepick = 1
                break;
                case 'even_n':
                    this.multiplier = this.rate_nor_oddeven
                    this.pickName = '일반볼 짝'
                    this.picknum = 2
                    this.typepick = 1
                break;
                case 'under_n':
                    this.multiplier = this.rate_nor_underover
                    this.pickName = '일반볼 언더'
                    this.picknum = 1
                    this.typepick = 2
                break;
                case 'over_n':
                    this.multiplier = this.rate_nor_underover
                    this.pickName = '일반볼 오버'
                    this.picknum = 2
                    this.typepick = 2
                break;
                case 'odd_under_n':
                    this.multiplier = this.rate_nor_cross
                    this.pickName = '일홀-일언'
                    this.picknum = 1
                    this.typepick = 3
                break;
                case 'odd_over_n':
                    this.multiplier = this.rate_nor_cross
                    this.pickName = '일홀-일오'
                    this.picknum = 2
                    this.typepick = 3
                break;
                case 'even_under_n':
                    this.multiplier = this.rate_nor_cross
                    this.pickName = '일짝-일언'
                    this.picknum = 3
                    this.typepick = 3
                break;
                case 'even_over_n':
                    this.multiplier = this.rate_nor_cross
                    this.pickName = '일짝-일오'
                    this.picknum = 4
                    this.typepick = 3
                break;
                case 'odd_p':
                    this.multiplier = this.rate_pow_oddeven
                    this.pickName = '파워볼 홀'
                    this.picknum = 1
                    this.typepick = 4
                break;
                case 'even_p':
                    this.multiplier = this.rate_pow_oddeven
                    this.pickName = '파워볼 짝'
                    this.picknum = 2
                    this.typepick = 4
                break;
                case 'under_p':
                    this.multiplier = this.rate_pow_underover
                    this.pickName = '파워볼 언더'
                    this.picknum = 1
                    this.typepick = 5
                break;
                case 'over_p':
                    this.multiplier = this.rate_pow_underover
                    this.pickName = '파워볼 오버'
                    this.picknum = 2
                    this.typepick = 5
                break;
                case 'odd_under_p':
                    this.multiplier = this.rate_pow_cross1
                    this.pickName = '파홀-파언'
                    this.picknum = 1
                    this.typepick = 6
                break;
                case 'odd_over_p':
                    this.multiplier = this.rate_pow_cross2
                    this.pickName = '파홀-파오'
                    this.picknum = 2
                    this.typepick = 6
                break;
                case 'even_under_p':
                    this.multiplier = this.rate_pow_cross2
                    this.pickName = '파짝-파언'
                    this.picknum = 3
                    this.typepick = 6
                break;
                case 'even_over_p':
                    this.multiplier = this.rate_pow_cross1
                    this.pickName = '파짝-파오'
                    this.picknum = 4
                    this.typepick = 6
                break;                
                
            }
            this.updateHit()
        },
        updateHit(){
            this.hitAmount = this.betAmount*this.multiplier
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        getListInfo() {
            this.axios
            .get(this.api_infouser, {
                headers: {
                    Authorization:
                        "Bearer " + window.localStorage.getItem("token")
                }
            })
            .then((response) => {
                const amount = response.data.data.user.amount;
                const casino = response.data.data.user.casino;
                this.total_mn = Number(amount) + Number(casino);
            })
            .catch((e) => {
                console.log(e)
            });
        },
        
        updateClock() {
            setInterval(()=>{
                const now = new Date();
                const formatter = new Intl.DateTimeFormat('en', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
                });

                this.time = formatter.format(now);
            }, 1000)
        },
        loadMoney () {            
            let token_users = window.localStorage.getItem('token')
            this.axios.post(this.api_restInfo, 
                {
                    // code: '2' 
                },
                { 
                    headers: {
                        Authorization: 'Bearer ' + token_users
                    }
                }
            ).then((response) => {                
                this.total_cash = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(Number(response.data.data.user.amount) + Number(response.data.data.user.casino))
            })
        },
        updateTimer(timer){

                
                let secondsRemaining = timer;
                
                let timerInterval = setInterval(()=>{

                    if(secondsRemaining >= 0)
                    {
                        const minutes = Math.floor(secondsRemaining / 60);
                        const seconds = secondsRemaining % 60;
                        this.timer = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;                        
                        secondsRemaining--;
                    }else{
                        
                        const minutes = Math.floor(0 / 60);
                        const seconds = 0 % 60;
                        this.timer = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                        
                        if (secondsRemaining === -20) {
                            clearInterval(timerInterval);
                            this.getListGame();
                            //this.updateTimer()
                            //this.round++
                        } else {
                            secondsRemaining--;
                        }
                    }
               


            },1000)
        }
    }
}
</script>