<script setup>
import { onBeforeMount, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";

const { proxy } = getCurrentInstance();
const store = useStore();
const list = ref([]);

onBeforeMount(() => {
  if (proxy.token) {
    const token_user = window.localStorage.getItem("token");
    proxy.axios
      .get(proxy.api_listnotifi, {
        headers: {
          Authorization: "Bearer " + token_user,
        },
      })
      .then((response) => {
        list.value = response.data.data;
      });
  } else {
    proxy.axios.get(proxy.api_listnotifilist, {}).then((response) => {
      list.value = response.data.data;
      //**test
      list.value.push({ title: "Test notice" });
    });
  }
});
</script>

<template>
  <section
    class="lqd-section blog notice pt-50 pb-50"
    style="background-image: linear-gradient(180deg, #f1f2f3 0%, #fff 25%)"
  >
    <div class="container p-0">
      <div class="row m-0">
        <div class="col col-12">
          <div class="ld-fancy-heading main-boxcontent-tit">
            <h2 class="ld-fh-element m-0 text-22">
              {{ store.getters.langweb("fe.notification") || "Latest Notice" }}
            </h2>
          </div>
        </div>

        <div
          class="relative col col-12 cold-md-6 col-xl-4"
          v-for="(item, index) in list.slice(0, 5)"
          v-bind:key="index"
        >
          <article
            class="lqd-iconbox-scale transition-all post type-post status-publish ormat-standard has-post-thumbnail hentry category-blog-single"
          >
            <div class="lqd-lp-meta lqd-lp-meta-dot-between">
              <!--              <ul-->
              <!--                class="lqd-lp-cat lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid lqd-lp-cat-solid-colored reset-ul inline-ul relative z-3">-->
              <!--                <li><a class="module-color-4" href="./Mypage.html?mode=notice" rel="tag">Update</a></li>-->
              <!--              </ul>-->
              <time class="lqd-lp-date" datetime="2024-06-10">2024-06-10</time>
            </div>
            <a
              href="javascript:void(0)"
              @click="store.dispatch('showHomePopup', { open: true, tab: 5 })"
            >
              <h5 class="entry-title lqd-lp-title">
                {{ item.title.substring(0, 25) }}
              </h5>
            </a>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
