<script setup>
import {defineProps, onMounted} from 'vue';

defineProps({
  items: Array,
})

onMounted(() => {
});

</script>

<template>
  <marquee
    class="min-w-100"
    direction="up"
    scrolldelay="200"
    height="236px"
  >
    <ul class="m-0 p-0">
      <li
        class="grid grid-cols-2 min-w-fit" style="color: black;"
        v-for="(item, index) in items"
        v-bind:key="index"
      >
        <div class="user min-w-fit">
          <span>
            {{ item.fullname }}
          </span>
        </div>
        <div
          :class="[
            'amount min-w-fit',
            'ld-fh-element inline-block ld-gradient-heading relative bg-transparent module-gradient lqd-unit-animation-done',
          ].join(' ')"
          style="background-image: linear-gradient(50deg, rgb(141, 101, 233) 0%, rgb(107, 205, 148) 100%);"
        >
          <span>
            {{
              new Intl.NumberFormat(
                "en-US",
                {
                  minimumFractionDigits: 0
                }
              ).format(item.amount)
            }}원
          </span>
        </div>
        <!--<div class="date">
            <span>{{ item.created_at }}</span>
        </div> -->
      </li>
    </ul>
  </marquee>
</template>

<style scoped>

</style>