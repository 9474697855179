<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const maintenancePopup = computed(() => store.state.maintenancePopup);
</script>

<template>
  <div class="modal-vue maintenancePopup" v-if="maintenancePopup">
    <div
      class="overlay"
      @click="store.dispatch('maintenancePopup', false)"
    ></div>
    <div class="modal">
      <div class="wp-modal">
        <div class="header-modal">
          <div class="title-header">
            {{ store.getters.langweb("fe.notification") }}
          </div>
          <button
            class="close-modal"
            @click="store.dispatch('maintenancePopup', false)"
          >
            x
          </button>
        </div>
        <div class="content-modal">
          <div class="text-content">
            {{ store.getters.langweb("fe.maintenance") }}
          </div>
        </div>
        <div class="footer-modal">
          <button
            class="config-modal"
            @click="store.dispatch('maintenancePopup', false)"
          >
            {{ store.getters.langweb("fe.ok") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
