<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const banners = ref([
  {
    imgURL: "/assets_nx/images/main/img_main_slide_banner_11.png",
    title: "live casino",
    move: () => scrollToElement("new-casino-section"),
  },
  {
    imgURL: "/assets_nx/images/main/img_main_slide_banner_12.png",
    title: "slot game",
    move: () => scrollToElement("new-slotgame-section"),
  },
  {
    imgURL: "/assets_nx/images/main/img_main_slide_banner_13.png",
    title: "mini game",
    move: () => scrollToElement("minigame-section"),
  },
]);


const onClickItem = () => {
  if (!store.state.loggedIn) {
    store.dispatch("showNotificationPopup", true);
  } else {
    store.dispatch("getGameProcess", {
      api: "nexus",
      code: "bti_sports",
      type: 2,
    });
  }
};

function scrollToElement(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
</script>

<template>
  <section class="lqd-section gamebanner-sub main sm:hidden">
    <div class="container">
      <div class="row">
        <div
          v-for="(item, index) in banners"
          :key="index"
          class="col col-12 col-sm-4 col-md-4 col-lg-4 relative xs:-mt-40"
        >
          <a
            href="javascript:void(0)"
            class="hover:scale-1/1"
            @click="item.move"
          >
            <div class="lqd-imggrp-single" data-hover3d="true">
              <div class="lqd-imggrp-img-container" data-stacking-factor="1">
                <figure>
                  <img
                    :src="item.imgURL"
                    width="640"
                    height="290"
                    :title="item.title"
                    :alt="item.title"
                  />
                </figure>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="relative flex justify-center">
        <a
          href="javascript:void(0)"
          class="hover:scale-1/1 w-100"
          @click="onClickItem()"
        >
          <img
            class="banner-bg"
            src="/assets_nx/images/main/img_main_slide_banner_14.png"
            title="mini game"
            alt="mini game"
            style="width: 100%; height: 230px"
          />
        </a>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
