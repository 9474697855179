<script setup>

</script>

<template>
  <footer id="site-footer" class="main-footer bg-transparent text-white"
          style="background-image: linear-gradient(120deg, #323945 78%, #514A65 100%);">
    <div class="transition-all bg-cover pt-60 pb-60 module-bg">
      <div class="container">
        <div class="row">
          <div class="col col-12 flex flex-row flex-wrap" data-custom-animations="true"
               data-ca-options='{"animationTarget": "figure", "duration" : 750, "delay" : 55, "ease": "expo.out", "initValues": {"scaleX" : 0.5, "scaleY" : 0.5, "opacity" : 0}, "animations": {"scaleX" : 1, "scaleY" : 1, "opacity" : 1}}'>
            <ul class="foot-partner-list lg-logos">
              <li>
                <figure><img src="/assets_nx/images/p-logo/partner_logos-set01.png" alt=""></figure>
              </li>
              <li>
                <figure><img src="/assets_nx/images/p-logo/partner_logos-set02.png" alt=""></figure>
              </li>
              <li>
                <figure><img src="/assets_nx/images/p-logo/partner_logos-set03.png" alt=""></figure>
              </li>
            </ul>
          </div>

          <div class="col col-12 p-0">
            <section class="lqd-section footer-info flex flex-wrap items-center justify-between pt-30"
                     data-custom-animations="true"
                     data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "opacity" : 0}, "animations": {"y": "0px", "opacity" : 1}}'>
              <div class="w-30percent flex justify-start p-10 lg:w-full lg:justify-start animation-element sm:pb-60">
                <div class="ld-fancy-heading relative animation-element">
                  <h2
                    class="text-36 leading-1em mb-30 tracking-0 text-white ld-fh-element relative lqd-highlight-custom lqd-highlight-custom-2 w-full text-center"
                    data-inview="true" data-transition-delay="true"
                    data-delay-options=' {"elements": ".lqd-highlight-inner", "delayType": "transition"}'>
                    <mark class="lqd-highlight" style="padding: 0 40px 0 40px">
                      <span class="lqd-highlight-txt">New Sia</span>
                      <span class="left-0 -bottom-20 lqd-highlight-inner">
                        <svg class="lqd-highlight-brush-svg lqd-highlight-brush-svg-2"
                             width="233" height="13" viewbox="0 0 233 13"
                             xmlns="http://www.w3.org/2000/svg"
                             aria-hidden="true"
                             preserveaspectratio="none">
                          <path
                            d="m.624 9.414-.312-2.48C0 4.454.001 4.454.002 4.454l.035-.005.102-.013.398-.047c.351-.042.872-.102 1.557-.179 1.37-.152 3.401-.368 6.05-.622C13.44 3.081 21.212 2.42 31.13 1.804 50.966.572 79.394-.48 113.797.24c34.387.717 63.927 2.663 84.874 4.429a1048.61 1048.61 0 0 1 24.513 2.34 641.605 641.605 0 0 1 8.243.944l.432.054.149.02-.318 2.479-.319 2.48-.137-.018c-.094-.012-.234-.03-.421-.052a634.593 634.593 0 0 0-8.167-.936 1043.26 1043.26 0 0 0-24.395-2.329c-20.864-1.76-50.296-3.697-84.558-4.413-34.246-.714-62.535.332-82.253 1.556-9.859.612-17.574 1.269-22.82 1.772-2.622.251-4.627.464-5.973.614a213.493 213.493 0 0 0-1.901.22l-.094.01-.028.004Z"
                            fill="#49C193"></path>
                        </svg>
                        <svg class="lqd-highlight-pen" width="30" height="30" viewBox="0 0 30 30"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M24.706 6.647l-9.412-5.435a2.353 2.353 0 00-2.353 0l-9.412 5.435a2.353 2.353 0 00-.491.378l11.08 6.395 11.08-6.396a2.353 2.353 0 00-.492-.377zm-9.273.832a2.89 2.89 0 01-2.624 0c-.728-.42-.735-1.102-.012-1.522a2.907 2.907 0 012.632 0 .805.805 0 01.004 1.522z"/>
                          <path
                            d="M2.445 8.04a2.353 2.353 0 00-.092.644v10.868a2.353 2.353 0 001.176 2.037l9.412 5.434c.184.106.38.19.588.239V15.176L2.445 8.04zm2.558 6.67a2.408 2.408 0 01-1.095-1.897c0-.695.494-.975 1.103-.624a2.407 2.407 0 011.091 1.89c-.002.7-.493.98-1.099.63zm2.644 4.662a2.408 2.408 0 01-1.095-1.898c0-.695.493-.975 1.102-.624a2.405 2.405 0 011.092 1.891c-.004.7-.495.98-1.099.63zm2.642 4.662a2.408 2.408 0 01-1.095-1.897c0-.695.493-.975 1.102-.624a2.407 2.407 0 011.092 1.89c-.004.7-.496.98-1.101.63z"/>
                          <path
                            d="M25.79 8.04l-11.084 6.4v12.823a2.33 2.33 0 00.588-.239l9.412-5.435a2.353 2.353 0 001.176-2.038V8.684a2.353 2.353 0 00-.092-.644zm-7.411 6.967c.609-.353 1.1-.071 1.102.628a2.405 2.405 0 01-1.094 1.895c-.605.349-1.1.071-1.103-.624a2.415 2.415 0 011.096-1.9zm.021 7.568c-.605.349-1.1.071-1.102-.624a2.412 2.412 0 011.095-1.897c.609-.353 1.1-.071 1.103.628a2.412 2.412 0 01-1.095 1.893zm4.372-10.104c.609-.353 1.1-.071 1.103.628a2.407 2.407 0 01-1.095 1.893c-.605.349-1.097.069-1.099-.626a2.407 2.407 0 011.091-1.895zm.022 7.568c-.605.349-1.097.069-1.099-.626a2.405 2.405 0 011.092-1.895c.609-.353 1.1-.071 1.102.628a2.405 2.405 0 01-1.095 1.893z"/>
                        </svg>
                      </span>
                    </mark>
                  </h2>
                  <p
                    class="text-13 leading-1em font-normal text-white-70 w-full text-center ld-fh-element mb-0/5em inline-block relative">
                    © <strong>New Sia</strong>. 無断転載を禁じます.</p>
                </div>
              </div>
              <div class="w-70percent flex flex-wrap p-10 lg:w-full animation-element sm:hidden">
                <div
                  class="ld-fancy-heading relative text-start text-12 leading-16 opacity-50 w-100percent lg:w-full module-copyright">
                  New Sia は、1 日 24 時間、週 7 日、年間 365 日楽しめる 100 種類以上の最高のカジノ ゲームを備えた新しいオンライン カジノおよびモバイル カジノです。
                  自宅やどこからでも、携帯電話やモバイル タブレット デバイスを使用して、安全なカジノ
                   サイトで安全に最高の新しいカジノ ゲームをお楽しみください。.<br>
                  New Sia 最高のオンライン スロット ゲームとモバイル スロット マシンをホストしており、
                  いつでも楽しめる 100 以上のトップ スロット ゲームが用意されています。リールを 1 回クリックするだけで、
                  数秒で億万長者になれる、最も人気のあるプログレッシブ ゲームも用意しています。今すぐ登録またはログインしてプレイしてください。
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>

</style>