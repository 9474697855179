<script setup>
import { ref } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { useStore } from "vuex";

const store = useStore();

const banners = ref([
  {
    imgURL: "/assets_nx/images/main/img_main_slide_banner_11.png",
    title: "live casino",
    href: "javascript:void(0)",
    move: () => scrollToElement("new-casino-section"),
  },
  {
    imgURL: "/assets_nx/images/main/img_main_slide_banner_12.png",
    title: "mini game",
    href: "javascript:void(0)",
    move: () => scrollToElement("new-slotgame-section"),
  },
  {
    imgURL: "/assets_nx/images/main/img_main_slide_banner_13.png",
    title: "slot game",
    href: "javascript:void(0)",
    move: () => scrollToElement("new-minigame-section"),
  },
  
]);


const onClickItem = () => {
  if (!store.state.loggedIn) {
    store.dispatch("showNotificationPopup", true);
  } else {
    store.dispatch("getGameProcess", {
      api: "nexus",
      code: "bti_sports",
      type: 2,
    });
  }
};

function scrollToElement(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}


</script>

<template>
  <div class="hidden sm:!block">
    <Carousel
      class="banner-section w-ba"
      :autoplay="2000"
      :wrap-around="true"
      :mouseDrag="true"
      :touchDrag="true"
      :pauseAutoplayOnHover="true"
    >
      <Slide v-for="item in banners" :key="item">
        <div class="relative">
          <a
            href="javascript:void(0)"
            class="hover:scale-1/1"
            @click="item.move"
          >
            <img
              class="banner-bg"
              width="640"
              height="290"
              :src="item.imgURL"
              :title="item.title"
              :alt="item.title"
            />
          </a>
        </div>
      </Slide>
    </Carousel>
    <div class="relative flex justify-center my-2 mx-0 px-0">
      <a
        href="javascript:void(0)"
        class="hover:scale-1/1"
        @click="onClickItem()"
      >
        <img
          class="banner-bg"
          src="/assets_nx/images/main/img_main_slide_banner_14.png"
          title="mini game"
          alt="mini game"
          style="width: 100%; height: 190px"
        />
      </a>
    </div>
  </div>
</template>

<style>
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
