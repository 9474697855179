<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import NotLoggedInBar from "@/components/header/NotLoggedInBar.vue";
import LoggedInBar from "@/components/header/LoggedInBar.vue";

const store = useStore();
const loggedIn = computed(() => store.state.loggedIn);
</script>

<template>
  <section class="lqd-section bg-darkpurple transition-all pt-10 md:hidden">
    <LoggedInBar v-if="loggedIn" />
    <NotLoggedInBar v-else />
  </section>
</template>

<style scoped></style>
