<script setup>
import { getCurrentInstance, computed } from "vue";
import { useStore } from "vuex";

const { proxy } = getCurrentInstance();
const store = useStore();
const user = computed(() => store.state.user);

const logout = (e) => {
  e.preventDefault();
  proxy.axios
    .post(
      proxy.api_logout,
      {},
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (res.data.success === true) {
        localStorage.clear();
        proxy.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        window.location.href = "/";
      }
    });
};
</script>

<template>
  <div class="lqd-mobile-sec-nav w-full absolute z-10">
    <div
      class="mobile-navbar-collapse navbar-collapse collapse w-full"
      id="lqd-mobile-sec-nav"
      aria-expanded="false"
      role="navigation"
    >
      <div class="m-userinfo-box">
        <div class="userinfo">
          <div class="infoBox module-col-2">
            <ul class="reset-ul usercost" style="width: 100%">
              <li class="usercostCash">
                <a
                  href="javascript:void(0)"
                  class="btn button-gra-red"
                  @click="
                    store.dispatch('showHomePopup', { open: true, tab: 1 })
                  "
                >
                  <span>
                    <i class="fi fi-rr-money-bill-wave"></i>
                    {{ store.getters.langweb("fe.amount") }}
                  </span>
                  &nbsp;
                  <strong>{{
                    new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                    }).format(user.totalAmount)
                  }}</strong>
                </a>
              </li>
              <li class="usercostComp">
                <a
                  href="javascript:void(0)"
                  class="btn button-gra-orange ml-1"
                  @click="
                    store.dispatch('showHomePopup', { open: true, tab: 8 })
                  "
                >
                  <span>
                    <i class="fi fi-rr-badge-percent"></i>
                    {{ store.getters.langweb("fe.point") }}
                  </span>
                  &nbsp;
                  <strong>{{
                    new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 0,
                    }).format(user.point)
                  }}</strong>
                </a>
              </li>
            </ul>
          </div>
          <div class="welcomBox module-col-1">
            <ul
              id="mobile-primary-nav"
              class="reset-ul lqd-mobile-main-nav main-nav nav"
            >
              <li class="menu-item-has-children">
                <div class="mo-submenu-indepth">
                  <p class="ld-fh-element">
                    <i class="fi fi-rr-clover-alt"></i>
                    ようこそ,
                    <strong>{{ user.name }}</strong>
                    
                  </p>
                </div>

                <button
                  class="button-fully-white flex items-center justify-center gap-2"
                  @click="logout"
                >
                  <span>Logout</span>
                  <span>
                    <i class="fi fi-rr-sign-out-alt"></i>
                  </span>
                </button>

                <!--                <ul class="nav-item-children" style="display: block">-->
                <!--                  <li>-->
                <!--                    <a href="./Mypage.html">내정보</a>-->
                <!--                  </li>-->
                <!--                  <li>-->
                <!--                    <a href="./Message.html">쪽지</a>-->
                <!--                  </li>-->
                <!--                  <li>-->
                <!--                    <a href="./Amount.html">입금내역</a>-->
                <!--                  </li>-->
                <!--                  <li>-->
                <!--                    <a href="./Withdrawal.html">출금내역</a>-->
                <!--                  </li>-->
                <!--                  <li>-->
                <!--                    <a href="./Customer.html">고객센터</a>-->
                <!--                  </li>-->
                <!--                </ul>-->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
