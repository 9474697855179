<template>
  <div v-if="mainHide == 1">
    <div v-if="$route.path == '/'">
      <Header_city />
      <router-view :key="$route.params" />
      <FooterMain />
    </div>
    <div v-else>
      <Header_city />
      <div class="page-content">
        <div class="route-content container">
          <router-view :key="$route.params" />
        </div>
      </div>
      <FooterMain />
    </div>
  </div>
  <div class="bgr_maintenance" v-else>
    <div class="container maintenance">
      <img src="./assets/images/system.png" alt="" />
      <h1 class="error-title"><span>We’ll be back soon!</span></h1>
      <div v-html="message" class="text_maintenance"></div>
    </div>
  </div>
</template>
<script>
//css
//**del
// import "./assets/css/style.css"
// import "./assets/css/style2.css"
// import "./assets/css/animate.min.css"
// import "./assets/css/aos.css"
// import "./assets/css/bootstrap.min.css";
import "./assets/css/all.min.css";
import "./assets/css/tailwind.css";

import Header_city from "./components/Header.vue";
import FooterMain from "./components/footer/FooterMain.vue";

export default {
  name: "App",
  data() {
    return {
      mainHide: 1,
      message: "",
    };
  },
  components: {
    Header_city,
    FooterMain,
  },
  created() {
    this.mainTenance();
    setInterval(this.mainTenance, 5000);
  },
  mounted() {
    const scripts = [
      //**del
      // '/assets_nx/js/liquid-gdpr.min.js',
      // '/assets_nx/js/liquid-ajax-contact-form.min.js',
      "/assets_nx/js/theme.min.js",
    ];
    scripts.forEach((src, i) => {
      const script = document.createElement("script");
      script.src = src;
      setTimeout(() => {
        document.body.appendChild(script);
      }, 300 + 100 * i);
    });
  },
  methods: {
    mainTenance() {
      this.axios
        .get(this.api_Setting)
        .then((res) => {
          if (res.data.success === true) {
            this.mainHide = 1;
          } else {
            this.mainHide = 1;
          }
        })
        .catch((e) => {
          this.message = e.response.data.message;
          if (e.response.status == 503) {
            this.mainHide = 0;
          }
        });
    },
  },
};
</script>

<style></style>
