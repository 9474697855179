<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import $ from "jquery";
import Cashin_city from "@/components/Cashin.vue";
// import ListCashin_city from "@/components/ListCashIn";
import Cashout_city from "@/components/Cashout.vue";
import ListCashout_city from "@/components/ListCashOut.vue";
import Notification_city from "@/components/Notification.vue";
import Chat_city from "@/components/ChatList.vue";
import Contact_city from "@/components/SendChat.vue";
import ChangeInfo_city from "@/components/ChangeInfo.vue";
import PointToAmount_city from "@/components/PointToAmount.vue";
import Betting_city from "@/components/Betting.vue";

import Send_city from "@/components/Send.vue";


const store = useStore();
const selectedTab = computed(() => store.state.homePopupTab);
const tabTitle = ref("");

function changeTab(e, tab) {
  tabTitle.value = e.target.textContent;
  store.dispatch("showHomePopup", { open: true, tab });
}

function close() {
  $("body").css("overflow", "auto");
  store.dispatch("showHomePopup", { open: false });
}
</script>

<template>
  <div class="subpage-wrapper d-block" v-if="store.state.homePopup">
    <div class="subpage-container">
      <button class="close-subpage" @click="close">x</button>
      <div class="subpage-inner">
        <div class="modal-contents">
          <div class="side-menu">
            <div class="sidemenu-header">リストを選択</div>
            <div class="menu">
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab1')"
                ><i class="fa-solid fa-piggy-bank"></i
                >{{ store.getters.langweb("fe.cashin") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab3')"
                ><i class="fa-solid fa-vault"></i
                >{{ store.getters.langweb("fe.cashout") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab4')"
                ><i class="fa-solid fa-list"></i
                >{{ store.getters.langweb("fe.history") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab8')"
                ><i class="fa-solid fa-money-bill-transfer"></i
                >{{ store.getters.langweb("fe.pointtoamount") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab5')"
                ><i class="fa-solid fa-bell"></i
                >{{ store.getters.langweb("fe.notification") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab6')"
                ><i class="fa-solid fa-comment-dots"></i
                >{{ store.getters.langweb("fe.chat") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab11')"
                ><i class="fa-solid fa-comments"></i
                >{{ store.getters.langweb("fe.sends") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab7')"
                ><i class="fa-solid fa-circle-info"></i
                >{{ store.getters.langweb("fe.info") }}</a
              >
              <a
                href="javascript:void(0)"
                class="menu-item"
                @click="(e) => changeTab(e, 'tab9')"
                ><i class="fa-solid fa-clock-rotate-left"></i
                >{{ store.getters.langweb("fe.betting") }}</a
              >
             
            </div>
          </div>
          <div class="subpage-content">
            <div class="subpagecontent-header">
              {{ tabTitle }}
            </div>
            <div class="content">
              <div
                id="deposit"
                class="active tab1"
                v-if="selectedTab === 'tab1'"
              >
                <Cashin_city />
              </div>
              <div
                id="withdraw"
                class="active tab3"
                v-else-if="selectedTab === 'tab3'"
              >
                <Cashout_city />
              </div>
              <div
                id="announcement"
                class="table-responsive active tab5"
                v-else-if="selectedTab === 'tab5'"
              >
                <Notification_city />
              </div>
              <div
                id="conversion"
                class="active tab8"
                v-else-if="selectedTab === 'tab8'"
              >
                <PointToAmount_city />
              </div>
              <div
                id="inquiry"
                class="table-responsive active tab6"
                v-else-if="selectedTab === 'tab6'"
              >
                <Chat_city />
                <Contact_city />
              </div>
              <div
                id="note"
                class="active tab11"
                v-else-if="selectedTab === 'tab11'"
              >
                <Send_city />
              </div>
              <div
                id="transaction-details"
                class="table-responsive active tab4"
                v-else-if="selectedTab === 'tab4'"
              >
                <ListCashout_city />
              </div>
              <div
                id="mypage"
                class="active tab7"
                v-else-if="selectedTab === 'tab7'"
              >
                <ChangeInfo_city />
              </div>
              <div
                id="betting-details"
                class="table-responsive active tab9"
                v-else-if="selectedTab === 'tab9'"
              >
                <Betting_city />
              </div>
              <div
                id="faq"
                class="table-responsive active tab12"
                v-else-if="selectedTab === 'tab12'"
              >
               
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
