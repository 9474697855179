<script setup></script>

<template>
  <div class="lqd-mobile-sec-nav w-full absolute z-10">
    <div
      class="mobile-navbar-collapse navbar-collapse collapse w-full"
      id="lqd-mobile-sec-nav"
      aria-expanded="false"
      role="navigation"
    >
      <div class="m-userinfo-box">
        <div class="userinfo">
          <div class="welcomBox module-col-1">
            <ul
              id="mobile-primary-nav"
              class="reset-ul lqd-mobile-main-nav main-nav nav"
            >
              <li class="menu-item-has-children">
                <h2 class="ld-fh-element">
                  <span class="stxt">
                    <i class="fi fi-rr-clover-alt"></i>
                    ようこそ, <strong>幸運を!</strong>
                  </span>
                </h2>
              </li>
            </ul>
            <div class="btnset-user">
              <a
                href="#modal-join"
                data-lity="#contact-modal"
                class="m-join-bt"
              >
                <font>{{ $store.getters.langweb("fe.registers") }}</font>
              </a>
              <a
                href="#modal-login"
                data-lity="#contact-modal"
                class="m-login-bt"
              >
                <font>{{ $store.getters.langweb("fe.login") }}</font>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--        <ul-->
      <!--          id="mobile-primary-nav"-->
      <!--          class="reset-ul lqd-mobile-main-nav main-nav nav"-->
      <!--          data-localscroll="true"-->
      <!--          data-localscroll-options='{"itemsSelector":"> li > a", "trackWindowScroll": true, "includeParentAsOffset": true}'-->
      <!--        >-->
      <!--          <li>-->
      <!--            <dl>-->
      <!--              <dd>-->
      <!--                <a-->
      <!--                  href="javascript:void(0)"-->
      <!--                  class="mo-menu"-->
      <!--                  style="-->
      <!--                    background-image: url(https://www.bk8thaff.com/public/html/images/casino/thumbnail/EVO/thai-baccarat.jpg);-->
      <!--                  "-->
      <!--                >-->
      <!--                  <span>Live Casino</span>-->
      <!--                  <div class="lqd-overlay"></div>-->
      <!--                </a>-->
      <!--              </dd>-->
      <!--              <dd>-->
      <!--                <a-->
      <!--                  href="javascript:void(0)"-->
      <!--                  class="mo-menu"-->
      <!--                  style="-->
      <!--                    background-image: url(https://www.bk8thaff.com/public/html/images/slot_img/slots-bn.jpg);-->
      <!--                  "-->
      <!--                >-->
      <!--                  <span>Slot game</span>-->
      <!--                  <div class="lqd-overlay"></div>-->
      <!--                </a>-->
      <!--              </dd>-->
      <!--            </dl>-->
      <!--          </li>-->
      <!--        </ul>-->
    </div>
  </div>
</template>

<style scoped></style>
