<script setup>
import { getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

const { proxy } = getCurrentInstance();
const store = useStore();
const username_lg = ref("");
const password_lg = ref("");

const login = (e) => {
  e.preventDefault();
  proxy.axios
    .post(proxy.api_login, {
      username: username_lg.value,
      password: password_lg.value,
    })
    .then((res) => {
      if (res.data.success === true) {
        window.localStorage.setItem("token", res.data.data.token);
        window.localStorage.setItem("level", res.data.data.user.level);
        window.localStorage.setItem("refcode", res.data.data.user.refcode);
        window.localStorage.setItem("offline", res.data.data.user.offline);
        let today = new Date();
        let date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        let time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        let timestamp = date + " " + time;
        window.localStorage.setItem("time_token", timestamp);
        proxy.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        window.location.href = "/";
      } else {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 404) {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: e.response.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
};
</script>

<template>
  <div
    id="modal-login"
    class="lity-modal lqd-modal lity-hide member"
    data-modal-type="compact"
  >
    <div class="lqd-modal-inner">
      <div class="lqd-modal-head">
        <p class="subtxt">IDを入力するか、参加してください！</p>
        <h4 class="ld-fh-element"><span>New Sia</span> メンバーシップ</h4>
      </div>
      <form method="post" name="LoginFrm" @submit="(e) => login(e)">
        <div class="lqd-modal-content">
          <div class="container p-0">
            <div class="flex flex-col">
              <div
                class="lqd-tabs lqd-tabs-style-9 lqd-tabs-nav-items-not-expanded lqd-nav-underline-"
              >
                <div class="py-15 w-full divider">
                  <span class="divider-separator"></span>
                </div>
                <div class="lqd-tabs-content relative pt-20 pb-60">
                  <div
                    id="lqd-tab-modal-login"
                    role="tabpanel"
                    class="lqd-tabs-pane active ca-initvalues-applied"
                    data-custom-animations="true"
                    data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"x": "50px", "opacity" : 0} , "animations": {"x": "0px", "opacity" : 1}}'
                  >
                    <div class="container animation-element">
                      <div class="login-input-box">
                        <div
                          class="ld-fancy-heading flex justify-center items-center"
                        >
                          <i>
                            <img
                              src="/assets_nx/images/icons/aicon-clovercoin.png"
                              alt="login icon"
                            />
                          </i>
                          <h3 class="ld-fh-element">
                            {{ store.getters.langweb("fe.login") }}
                          </h3>
                        </div>
                        <div
                          class="lqd-contact-form lqd-contact-form-inputs-underlined lqd-contact-form-button-block lqd-contact-form-button-lg lqd-contact-form-button-border-none"
                        >
                          <div role="form" lang="en-US">
                            <div class="row">
                              <div class="col col-12 relative py-0">
                                <i class="fi fi-rr-user-lock"></i>
                                <span class="lqd-form-control-wrap">
                                  <input
                                    class="loginInput"
                                    type="text"
                                    name="username_lg"
                                    v-model="username_lg"
                                    size="40"
                                    aria-required="true"
                                    aria-invalid="false"
                                    :placeholder="
                                      store.getters.langweb('fe.username')
                                    "
                                  />
                                </span>
                              </div>
                              <div class="col col-12 relative py-0">
                                <i class="fi fi-rr-lock-alt"></i>
                                <span class="lqd-form-control-wrap">
                                  <input
                                    class="loginInput"
                                    type="password"
                                    name="password_lg"
                                    v-model="password_lg"
                                    size="40"
                                    aria-required="true"
                                    aria-invalid="false"
                                    :placeholder="
                                      store.getters.langweb('fe.passwords')
                                    "
                                  />
                                </span>
                              </div>
                              <div class="col col-12 text-center relative py-0">
                                <input
                                  class="bg-primary text-white text-17 font-medium leading-1/5em hover:bg-accent hover:text-white"
                                  type="submit"
                                  data-lity-close=""
                                  :value="store.getters.langweb('fe.login')"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!--              <div class="lqd-modal-foot link-black">-->
      <!--                <div class="flex flex-wrap items-center justify-center">-->
      <!--                  <div-->
      <!--                    class="mr-10 px-10 rounded-100 bg-purple-100 ld-fancy-heading relative"-->
      <!--                  >-->
      <!--                    <p-->
      <!--                      class="m-0 text-12 font-normal text-purple-500 ld-fh-element relative"-->
      <!--                    >-->
      <!--                      고객문의-->
      <!--                    </p>-->
      <!--                  </div>-->
      <!--                  <div class="ld-fancy-heading relative">-->
      <!--                    <p class="m-0 text-13 ld-fh-element relative">-->
      <!--                      궁금하신 사항이 있으신가요?-->
      <!--                      <img src="/images/line_ico.jpg" style="width: 40px" /><a-->
      <!--                        href="#;"-->
      <!--                        class="underline"-->
      <!--                        >LINE ID:@910gudfh</a-->
      <!--                      >-->
      <!--                    </p>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
    </div>
  </div>
</template>

<style scoped></style>
