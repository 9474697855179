<script setup>
import { getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";

const { proxy } = getCurrentInstance();
const store = useStore();

const partner_code = ref("");
const name = ref("");
const username = ref("");
const password = ref("");
const password_confirm = ref("");
const number_cash = ref("");
const phone = ref("");
const name_bank = ref("");
const number_bank = ref("");
const user_bank = ref("");

const register = (e) => {
  e.preventDefault();
  proxy.axios
    .post(proxy.api_register, {
      partner_code: partner_code.value,
      name: name.value,
      username: username.value,
      password: password.value,
      password_confirm: password_confirm.value,
      number_cash: number_cash.value,
      phone: phone.value,
      name_bank: name_bank.value,
      number_bank: number_bank.value,
      user_bank: user_bank.value,
    })
    .then((res) => {
      if (res.data.success === true) {
        //**??: no data
        // window.localStorage.setItem("token", res.data.data.token);
        // window.localStorage.setItem("level", res.data.data.user.level);
        // window.localStorage.setItem("offline", res.data.data.user.offline);
        // let today = new Date();
        // let date =
        //   today.getFullYear() +
        //   "-" +
        //   (today.getMonth() + 1) +
        //   "-" +
        //   today.getDate();
        // let time =
        //   today.getHours() +
        //   ":" +
        //   today.getMinutes() +
        //   ":" +
        //   today.getSeconds();
        // let timestamp = date + " " + time;
        // window.localStorage.setItem("time_token", timestamp);
        proxy.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        window.location.href = "/";
      } else {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: res.data.errors,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 404) {
        proxy.$swal({
          position: "top-end",
          icon: "error",
          title: e.response.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    });
};
</script>

<template>
  <div
    id="modal-join"
    class="lity-modal lqd-modal lity-hide member"
    data-modal-type="compact"
  >
    <div class="lqd-modal-inner">
      <div class="lqd-modal-head">
        <p class="subtxt">IDを入力するか、参加してください!</p>
        <h4 class="ld-fh-element"><span>New Sia</span> メンバーシップ</h4>
      </div>
      <form method="post" name="JoinFrm" @submit="(e) => register(e)">
        <div class="lqd-modal-content">
          <div class="container p-0">
            <div class="flex flex-col">
              <div
                class="lqd-tabs lqd-tabs-style-9 lqd-tabs-nav-items-not-expanded lqd-nav-underline-"
              >
                <div class="py-15 w-full divider">
                  <span class="divider-separator"></span>
                </div>
                <div class="lqd-tabs-content relative pt-20 pb-60">
                  <div
                    id="lqd-tab-modal-join"
                    role="tabpanel"
                    class="lqd-tabs-pane active ca-initvalues-applied"
                    data-custom-animations="true"
                    data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"x": "35px", "opacity" : 0} , "animations": {"x": "0px", "opacity" : 1}}'
                  >
                    <div class="container animation-element">
                      <div class="login-input-box">
                        <div
                          class="ld-fancy-heading flex justify-center items-center"
                        >
                          <i>
                            <img
                              src="/assets_nx/images/icons/aicon-crown.png"
                              alt="Join icon"
                            />
                          </i>
                          <h3 class="ld-fh-element">
                            {{ store.getters.langweb("fe.registers") }}
                          </h3>
                        </div>
                        <div
                          class="lqd-contact-form lqd-contact-form-inputs-underlined lqd-contact-form-button-block lqd-contact-form-button-lg lqd-contact-form-button-border-none"
                        >
                          <div role="form" lang="en-US">
                            <div class="row">
                              <div class="col col-12 relative py-0">
                                <span class="lqd-form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="partner_code"
                                    v-model="partner_code"
                                    autocomplete="off"
                                    :placeholder="
                                      $store.getters.langweb('fe.referrial')
                                    "
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="name"
                                    autocomplete="off"
                                    v-model="name"
                                    :placeholder="
                                      $store.getters.langweb(
                                        'fe.yournickname'
                                      ) + ' *'
                                    "
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="username"
                                    autocomplete="off"
                                    v-model="username"
                                    :placeholder="
                                      $store.getters.langweb('fe.yourid') + ' *'
                                    "
                                  />

                                  <input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    autocomplete="off"
                                    v-model="password"
                                    :placeholder="
                                      $store.getters.langweb('fe.passwords') +
                                      ' *'
                                    "
                                  />
                                  <input
                                    type="password"
                                    class="form-control"
                                    name="password_confirm"
                                    autocomplete="off"
                                    v-model="password_confirm"
                                    :placeholder="
                                      $store.getters.langweb(
                                        'fe.confirmpassword'
                                      ) + ' *'
                                    "
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="number_cash"
                                    autocomplete="off"
                                    v-model="number_cash"
                                    :placeholder="
                                      $store.getters.langweb('fe.numbercash') +
                                      ' *'
                                    "
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="phone"
                                    autocomplete="off"
                                    v-model="phone"
                                    :placeholder="
                                      $store.getters.langweb('fe.phone') + ' *'
                                    "
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="name_bank"
                                    autocomplete="off"
                                    v-model="name_bank"
                                    :placeholder="
                                      $store.getters.langweb('fe.namebank')
                                    "
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="number_bank"
                                    autocomplete="off"
                                    v-model="number_bank"
                                    :placeholder="
                                      $store.getters.langweb('fe.numberbank')
                                    "
                                  />
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="user_bank"
                                    autocomplete="off"
                                    v-model="user_bank"
                                    :placeholder="
                                      $store.getters.langweb('fe.userbank')
                                    "
                                  />
                                </span>
                              </div>
                              <div class="col col-12 text-center relative py-0">
                                <input
                                  class="btn modal_submit bg-warning text-white text-17 hover:bg-accent hover:text-white"
                                  type="submit"
                                  data-lity-close=""
                                  :value="store.getters.langweb('fe.signup')"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!--      <div class="lqd-modal-foot link-black">-->
      <!--        <div class="flex flex-wrap items-center justify-center">-->
      <!--          <div-->
      <!--            class="mr-10 px-10 rounded-100 bg-purple-100 ld-fancy-heading relative"-->
      <!--          >-->
      <!--            <p-->
      <!--              class="m-0 text-12 font-normal text-purple-500 ld-fh-element relative"-->
      <!--            >-->
      <!--              고객문의-->
      <!--            </p>-->
      <!--          </div>-->
      <!--          <div class="ld-fancy-heading relative">-->
      <!--            <p class="m-0 text-13 ld-fh-element relative">-->
      <!--              궁금하신 사항이 있으신가요?-->
      <!--              <img src="/images/line_ico.jpg" style="width: 40px" /><a-->
      <!--                href="#;"-->
      <!--                class="underline"-->
      <!--                >LINE ID:@910gudfh</a-->
      <!--              >-->
      <!--            </p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<style scoped></style>
