<script setup>
import { getCurrentInstance, computed } from "vue";
import { useStore } from "vuex";

const { proxy } = getCurrentInstance();
const store = useStore();
const user = computed(() => store.state.user);

const logout = (e) => {
  e.preventDefault();
  proxy.axios
    .post(
      proxy.api_logout,
      {},
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      }
    )
    .then((res) => {
      if (res.data.success === true) {
        localStorage.clear();
        proxy.$swal({
          position: "top-end",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 4000,
        });
        window.location.href = "/";
      }
    });
};
</script>

<template>
  <div class="top-info-link transition-all">
    <div class="container flex justify-between items-center pr-0">
      <div class="w-50percent flex items-center justify-start p-10 pr-0">
        <div class="ld-fancy-heading userwelcome">
          <p>
            <i class="fi fi-rr-clover-alt"></i>
            ようこそ,
            <strong>{{ user.name }}</strong>            
          </p>
        </div>
      </div>
      <div class="w-50percent flex items-center justify-end p-10 pr-0">
        <div class="flex justify-end w-full">
          <div class="ld-fancy-heading usercost relative btnset">
            <a
              href="javascript:void(0)"
              class="btn button-gra-red"
              @click="store.dispatch('showHomePopup', { open: true, tab: 1 })"
            >
              <span>
                <i class="fi fi-rr-money-bill-wave"></i>
                {{ store.getters.langweb("fe.amount") }}
              </span>
              <strong>{{
                new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 0,
                }).format(user.totalAmount)
              }}</strong>
            </a>
            <a
              href="javascript:void(0)"
              class="btn button-gra-orange ml-1"
              @click="store.dispatch('showHomePopup', { open: true, tab: 8 })"
            >
              <span>
                <i class="fi fi-rr-badge-percent"></i>
                {{ store.getters.langweb("fe.point") }}
              </span>
              <strong>{{
                new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 0,
                }).format(user.point)
              }}</strong>
            </a>
          </div>
          <div
            class="lqd-fancy-menu lqd-custom-menu lqd-menu-td-none transition-opacity pr-0"
          >
            <ul class="reset-ul inline-ul">
              <!--<li><a href="javascript:void(0)"><span><i class="fi fi-rr-dashboard"></i></span>마이페이지</a></li>-->
              <!--              <li>-->
              <!--                <a class="button-fully-sand" href="javascript:void(0)">-->
              <!--                  <span>-->
              <!--                    <i class="fi fi-rr-messages"></i>-->
              <!--                  </span>-->
              <!--                  쪽지-->
              <!--                </a>-->
              <!--                <p class="button-badge">1</p>-->
              <!--              </li>-->
              <li>
                <button class="button-fully-white" @click="logout">
                  <span>
                    <i class="fi fi-rr-sign-out-alt"></i>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
