<script setup>
import { useStore } from "vuex";

const store = useStore();
</script>

<template>
  <div class="top-info-link transition-all px-4">
    <div class="w-full flex justify-between items-center">
      <div class="w-50percent flex items-center justify-start p-10">
        <div class="ld-fancy-heading userwelcome">
          <p><i class="fi fi-rr-clover-alt"></i> ようこそ, 幸運を!</p>
        </div>
      </div>
      <div class="w-50percent flex items-center justify-end p-10">
        <div class="flex justify-end">
          <div
            class="lqd-fancy-menu lqd-custom-menu lqd-menu-td-none transition-opacity"
          >
            <ul class="reset-ul inline-ul">
              <li>
                <a href="#modal-login" data-lity="#contact-modal">
                  <span>
                    <i class="fi fi-rr-sign-in-alt"></i>
                  </span>
                  {{ store.getters.langweb("fe.login") }}
                </a>
              </li>

              <li>
                <a href="#modal-join" data-lity="#contact-modal">
                  <span>
                    <i class="fi fi-rr-id-card-clip-alt"></i>
                  </span>
                  {{ store.getters.langweb("fe.registers") }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
